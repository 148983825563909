/* eslint import/no-unresolved: [2, { ignore: ['\\.json$'] }] */

import appstore from 'components/Badge/default/ru/appstore.svg';
import googleplay from 'components/Badge/default/ru/googleplay.svg';
import huawei from 'components/Badge/default/ru/huawei.svg';
import rustore from 'components/Badge/default/ru/rustore.svg';
import messages from 'translations/ru-RU.json';
import {LocaleData} from 'types/LocaleData';

export const locale: LocaleData = {
  messages,
  badge: {
    default: {
      appstore,
      googleplay,
      huawei,
      rustore,
    },
  },
};
